import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"

export default function TermsAndConditions() {
  const Styled = styled.div`
    ol {
      padding-inline-start: 20px;
      list-style: decimal;
    }
    li {
      margin: 1rem 0;
    }
  `

  return (
    <Container>
      <Row>
        <Col>
          <Styled>
            <h2>Terms and Conditions of Sale</h2>
            <ol>
              <li>
                Every quotation, proforma invoice, price list or other similar
                document made or issued by Compulease Ltd (“the Company”) is
                made or issued subject to these Terms and Conditions of Sale and
                unless otherwise expressly agreed by the Company in writing no
                terms and conditions of sale which are at variance with these
                Terms and Conditions of Sale or any of them shall be construed
                as having any effect on any contract to which these Terms and
                Conditions apply.
              </li>
              <li>
                No order resulting from any quotation, proforma invoice, price
                list or other similar document made or issued by the Company
                shall be binding unless and until accepted by the Company. The
                Company at its absolute discretion may accept or reject any
                order whether on account of the equipment being no longer
                available or for any other reason whatsoever. All orders must be
                confirmed in writing, on the Purchaser’s official order form or
                on the Company’s official order and must be signed by a duly
                authorised official.
              </li>
              <li>
                The place of delivery is at the Purchaser’s nearest address in
                the United Kingdom or, in the case of export orders the nearest
                United Kingdom port or airport. All prices are ex-works unless
                otherwise stated.
              </li>
              <li>
                The Company’s normal terms of business are for all goods in
                stock to be despatched within 5 working days of cleared payment
                being received by the Company. In the event of goods not in
                stock, no cheques will be presented or credit cards charged
                until the goods are in stock and ready for despatch, unless the
                customer specifies otherwise. In the case of export orders
                payment will be by irrevocable letter of credit established with
                the Company’s main bank and payable on presentation of shipping
                documents or by cleared funds being transferred into the
                Company’s bank account.
              </li>
              <li>
                Purchase orders are only accepted from plc’s, government
                departments, education authorities and universities and even
                then at the sole discretion of the Company. All goods must be
                paid for within 7 days of despatch. The Company reserves the
                right to suspend delivery in respect of any order or orders from
                the Purchaser, if in the Company’s opinion, delivery would
                represent a credit risk to the Company. The Company may charge
                interest at a rate 6% above Bank of England Minimum Lending Rate
                on overdue accounts and may withdraw, at their discretion, any
                discounts allowed on the original invoice. In every case, goods
                supplied remain the property of the company until paid for in
                full.
              </li>
              <li>
                In the case of special orders, where the Company decides that
                the guarantee is inappropriate, the Purchaser will be informed
                of this fact prior to the goods being despatched. All goods
                returned under the guarantee scheme must be undamaged and in all
                the original packaging and any damage to the goods or packaging,
                external or internal, will invalidate the guarantee. All shrink
                wrapped software must be returned unopened. The Company is
                entitled to levy a restocking fee of 35% against any refund or
                cancelled order, in order to recover any of its costs associated
                with the transaction. In the case of special and bespoke orders,
                and where goods and costs have been incurred as a result of a
                signed order confirmation, the restocking fee may be increased
                and will be charged at a rate which enables the Company cover
                any costs associated with the transaction and to restore its
                original position had the order confirmation not been received.
              </li>
              <li>
                The Company will endeavor to deliver the equipment by the date
                specified but unless otherwise agreed in writing, time for
                delivery shall not be of the essence and the Company shall not
                be liable for any damages whatsoever resulting from delay in
                delivery howsoever caused. The Company may make installment
                deliveries.
              </li>
              <li>
                Any damage or shortage shall be reported to the Company and the
                Carriers in writing by the Purchaser within 7 days of delivery.
                Provided prompt notice of transit damage or loss is given and
                provided it is proved to the Company’s satisfaction that such
                damage occurred in transit in the United Kingdom, but not
                otherwise, the Company will at its sole discretion, repair or
                replace such equipment free of charge to the Purchaser.
              </li>
              <li>
                In the case of equipment not manufactured by the Company, the
                Purchaser shall have the benefit, in so far as it may be
                transferred to the Purchaser, of any rights which the Company
                may have against the supplier of such equipment in respect
                thereof and the Company’s liability in respect thereof is
                limited to making the benefit of such rights to the Purchase to
                the extent aforesaid.
              </li>
              <li>
                The Company shall not be liable for any indirect or
                consequential damage however caused (and whether by the Company
                or any other person) and shall not be liable for any damage
                (whether or not consequential) arising from stoppage or
                breakdown of the equipment for any reason whatsoever or in any
                other way for the performance for the equipment in operation.
              </li>
              <li>
                Should any equipment malfunction as a result of abnormal
                environmental factors (including without prejudice to the
                generality of the foregoing mains powers transients or dropouts,
                electromagnetic interference, extremes of humidity, vibration,
                temperature or pressure or chemical corrosion) which had not
                been notified to the Company at the time of placing the order
                and specifically accepted by the Company, then all costs
                incurred by the Company in repairing such equipment and
                investigating the causes of the malfunction shall be payable by
                the Purchaser.
              </li>
              <li>
                The Company hereby reserves the right to make without notice
                such minor modifications in specifications, design or materials
                as it may deem necessary from time to time.
              </li>
              <li>
                The Company shall not be liable for any failure to perform its
                obligations thereunder which may be due to any circumstances
                whatsoever beyond its control (including, without prejudice to
                the generality of the foregoing, any inability to obtain any
                necessary import or export licences or consent of any government
                or other authority.)
              </li>
              <li>
                All rights and title to any goods delivered to the Purchaser
                shall not pass to the Purchaser and the Purchaser shall keep the
                goods as bailee and trustee for the Company (returning the same
                to the Company upon request) until the price of the goods shall
                have been wholly paid to include any additional charges levied
                in respect of late payment, to the Company. Notwithstanding the
                foregoing, the Purchaser may sell the goods to third parties in
                the normal course of trade but the proceeds of any such sale,
                whenever any part of the price of the goods remains due to the
                Company, shall be held on trust for the Company and the
                Purchaser is deemed to assign to the Company the benefit of any
                claim which the Purchaser has against any such third party
                arising from such sale and/or delivery.
              </li>
              <li>
                The Company reserves the right not to undertake any repairs to
                equipment, unless the alleged fault is reported in writing.
              </li>
              <li>
                All contracts shall and in all respects be governed by and
                construed in accordance with the law of England.
              </li>
            </ol>
          </Styled>
        </Col>
      </Row>
    </Container>
  )
}
